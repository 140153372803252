<template>
    <header id="Header" :class="{ 'iframe': isIframeView() }">
        <div className="container">
            <div className="left">
                <nav>
                    <a href="https://www.jobse.nl" class="more-inline white">
                        <span class="icons filled back">
                            <span class="hover"><span v-html="Icons.arrow" class="icon"></span></span>
                            <span v-html="Icons.arrow" class="icon"></span>
                        </span>
                    </a>
                </nav>
            </div>
            <div className="middle">
                <Logo/>
            </div>
            <div className="right">
                <a href="https://www.jobse.nl" className="more-inline hasafter">
                    <span v-html="Icons.close" className="icon"></span>
                </a>
                <a href="tel:0031(0)88 033 4900" className="more outline white">
                    <span class="title">+31 (0)88 033 4900</span>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
import { defineComponent, inject } from "vue";
import { Icons } from "icons";
import Logo from "elements/logo";

//helpers
import {getQuery} from "helpers/_structure";

export default defineComponent({
    name: "headerElement",
    components: {
        Logo
    },
    setup() {
        const componentData = inject("componentData");
        const generalData = inject("generalData");
        const conditional = inject("conditional");

        const isIframeView = () => {
            return getQuery("view") === "iframe";
        };

        return {
            componentData,
            conditional,
            generalData,
            Icons,
            isIframeView
        };
    },
});
</script>

export const Icons = {
    arrow: `<svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
<polygon points="23.1,21.3 21.9,20.1 26.9,15.1 21.9,9.9 23.1,8.7 29.3,15.1 "/>
<rect x="0.7" y="14.2" width="27.4" height="1.7"/>
</svg>`,
    close: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            \t viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <rect x="23.6" y="-8.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3553 25.0005)" style="fill:#004361;" width="2.8" height="67.9"/>
            <rect x="-8.9" y="23.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3553 25)" style="fill:#004361;" width="67.9" height="2.8"/>
            </svg>`,
    energielabel: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.64 41.92">
                  <g id="Group_27457" data-name="Group 27457">
                    <path d="m25.07,31.87c-.06,0-.11,0-.17-.02-.32-.07-.55-.34-.58-.67l-.67-7.6-6.2-.64c-.26-.03-.49-.19-.6-.43s-.09-.52.06-.74l8.73-12.98c.18-.27.51-.39.82-.31.31.08.53.36.55.68l.4,6.46,6.19.64c.25.03.48.18.59.41.11.23.1.5-.03.72l-8.45,14.1c-.14.23-.38.36-.64.36Zm-6.22-10.29l5.58.57c.36.04.64.32.67.68l.52,5.91,6.65-11.11-5.65-.58c-.37-.04-.65-.33-.67-.7l-.3-4.88-6.8,10.1Z"/>
                    <g id="Group_27456" data-name="Group 27456">
                      <g id="Group_27458" data-name="Group 27458">
                        <path d="m11.75,41.92c-1.1,0-2.19-.3-3.16-.88-1.41-.85-2.4-2.19-2.8-3.78L.18,14.74c-.82-3.29,1.19-6.63,4.48-7.46L33.16.18c1.92-.48,3.96,0,5.47,1.29l10.85,9.26c2,1.7,2.7,4.49,1.73,6.93l-5.24,13.26c-.72,1.84-2.3,3.23-4.22,3.71l-28.49,7.1c-.5.12-1,.19-1.5.19ZM34.64,1.5c-.37,0-.75.04-1.12.14L5.03,8.74c-2.49.62-4.01,3.15-3.39,5.64l5.61,22.51c.3,1.21,1.05,2.22,2.12,2.86s2.31.83,3.52.53l28.49-7.1c1.45-.36,2.64-1.41,3.19-2.8,0,0,0,0,0,0l5.24-13.26c.73-1.85.2-3.95-1.31-5.24l-10.85-9.26c-.85-.72-1.92-1.11-3.02-1.11Z"/>
                      </g>
                    </g>
                  </g>
                </svg>`,
    bouwkundige_keuring: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.69 51.39">
                          <g id="Group_27484" data-name="Group 27484">
                            <path d="m36.8,51.39c-4.9,0-8.89-3.99-8.89-8.89s3.99-8.89,8.89-8.89,8.89,3.99,8.89,8.89-3.99,8.89-8.89,8.89Zm0-16.28c-4.07,0-7.39,3.32-7.39,7.39s3.32,7.39,7.39,7.39,7.39-3.32,7.39-7.39-3.32-7.39-7.39-7.39Z"/>
                            <path d="m36.12,45.29c-.19,0-.38-.07-.53-.22l-2.04-2.03c-.29-.29-.29-.77,0-1.06s.77-.29,1.06,0l1.5,1.5,2.86-2.86c.29-.29.77-.29,1.06,0s.29.77,0,1.06l-3.39,3.39c-.15.15-.34.22-.53.22Z"/>
                            <path d="m31.37,17.67H11.99c-.82,0-1.59-.32-2.17-.9-.58-.58-.9-1.35-.9-2.18v-1.94C8.98,5.74,14.65.06,21.56,0c7.01-.09,12.81,5.61,12.88,12.65v1.94c0,1.7-1.38,3.08-3.08,3.08ZM21.68,1.5s-.07,0-.1,0c-6.1.06-11.1,5.06-11.16,11.16v1.93c0,.42.16.82.46,1.12.3.3.69.46,1.11.46v.75s0-.75,0-.75h19.37c.87,0,1.58-.71,1.58-1.57v-1.94c-.06-6.17-5.1-11.15-11.26-11.15Z"/>
                            <path d="m34.47,17.67H8.89c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h25.58c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                            <path d="m21.68,31.62h0c-5.54,0-10.05-4.51-10.05-10.05v-4.65c0-.41.34-.75.75-.75s.75.34.75.75v4.65c0,4.71,3.84,8.55,8.55,8.55h0c2.28,0,4.43-.89,6.04-2.5s2.51-3.76,2.51-6.05v-4.65c0-.41.34-.75.75-.75s.75.34.75.75v4.65c0,2.68-1.05,5.21-2.95,7.11s-4.42,2.94-7.1,2.94Z"/>
                            <path d="m18.19,9.76c-.41,0-.75-.34-.75-.75V1.16c0-.41.34-.75.75-.75s.75.34.75.75v7.86c0,.41-.34.75-.75.75Z"/>
                            <path d="m25.17,9.76c-.41,0-.75-.34-.75-.75V1.16c0-.41.34-.75.75-.75s.75.34.75.75v7.86c0,.41-.34.75-.75.75Z"/>
                            <path d="m27.49,33.95c-.34,0-.64-.23-.73-.57l-.93-3.71c-.1-.4.14-.81.55-.91.4-.1.81.14.91.55l.93,3.71c.1.4-.14.81-.55.91-.06.02-.12.02-.18.02Z"/>
                            <path d="m.75,45.59C.34,45.59,0,45.25,0,44.84v-1.1c0-3.67,2.61-6.86,6.21-7.58l6.62-1.32c.61-.14,1.25-.07,1.82.17l1.42-5.69c.1-.4.51-.65.91-.55.4.1.65.51.55.91l-1.68,6.74c-.07.27-.27.48-.54.54-.26.07-.55-.01-.74-.21-.36-.39-.91-.55-1.43-.44l-6.64,1.33c-2.9.58-5.01,3.15-5,6.11v1.1c0,.41-.34.75-.75.75Z"/>
                          </g>
                        </svg>`,
    zonnepanelen: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.5 43.8">
                  <g id="renewable-energy-solar-panel">
                    <path d="m15.44,34.4h-.03c-.41-.02-.74-.36-.72-.78L15.86.73c.02-.41.36-.76.78-.72.41.01.74.36.72.78l-1.18,32.9c-.01.41-.35.72-.75.72Z"/>
                    <path d="m46.87,17.95H1.55c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h45.32c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                    <path d="m33.65,43.8H14.85c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h18.8c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                    <path d="m32.47,43.8c-2.4,0-4.27-3.43-4.27-7.8,0-.41.34-.75.75-.75s.75.34.75.75c0,3.71,1.46,6.3,2.77,6.3.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                    <path d="m16.02,43.8c-.41,0-.75-.34-.75-.75s.34-.75.75-.75c1.31,0,2.77-2.59,2.77-6.3,0-.41.34-.75.75-.75s.75.34.75.75c0,4.37-1.88,7.8-4.27,7.8Z"/>
                    <path d="m28.95,36.75c-.41,0-.75-.34-.75-.75v-2.35c0-.41.34-.75.75-.75s.75.34.75.75v2.35c0,.41-.34.75-.75.75Z"/>
                    <path d="m19.55,36.75c-.41,0-.75-.34-.75-.75v-2.35c0-.41.34-.75.75-.75s.75.34.75.75v2.35c0,.41-.34.75-.75.75Z"/>
                    <path d="m43.05,34.4H5.45c-3.01,0-5.45-2.45-5.45-5.45,0-.13,0-.26.01-.39L1.69,5.06C1.9,2.23,4.28,0,7.13,0h34.24c2.85,0,5.23,2.22,5.44,5.06l1.68,23.5c.1,1.45-.36,2.86-1.32,3.96-.95,1.1-2.28,1.76-3.73,1.87-.13,0-.26.01-.39.01ZM7.13,1.5c-2.06,0-3.79,1.61-3.94,3.67l-1.68,23.5c0,.1-.01.19-.01.28,0,2.18,1.77,3.95,3.95,3.95h37.6c.09,0,.19,0,.28,0,1.05-.08,2.01-.56,2.7-1.35.69-.8,1.03-1.82.96-2.87l-1.68-23.5c-.15-2.06-1.88-3.67-3.94-3.67H7.13Z"/>
                    <path d="m33.06,34.4c-.4,0-.74-.32-.75-.72L31.14.78c-.01-.41.31-.76.72-.78.4-.02.76.31.78.72l1.18,32.9c.01.41-.31.76-.72.78h-.03Z"/>
                  </g>
                </svg>`,
    advies: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.94 47.5">
              <g id="list-house">
                <path d="m25.64,47.5H5.86c-3.23,0-5.86-2.63-5.86-5.86V10.97c0-3.23,2.63-5.86,5.86-5.86h5.11c.41,0,.75.34.75.75s-.34.75-.75.75h-5.11c-2.4,0-4.36,1.96-4.36,4.36v30.67c0,2.41,1.96,4.36,4.36,4.36h19.78c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                <path d="m30.42,11.72H11.97c-.96,0-1.75-.79-1.75-1.75V1.75c0-.96.79-1.75,1.75-1.75h18.44c.96,0,1.75.79,1.75,1.75v8.22c0,.96-.79,1.75-1.75,1.75ZM11.97,1.5c-.14,0-.25.11-.25.25v8.22c0,.14.11.25.25.25h18.44c.14,0,.25-.11.25-.25V1.75c0-.14-.11-.25-.25-.25H11.97Z"/>
                <path d="m41.64,30.5c-.41,0-.75-.34-.75-.75V10.97c0-2.4-1.96-4.36-4.36-4.36h-5.11c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5.11c3.23,0,5.86,2.63,5.86,5.86v18.78c0,.41-.34.75-.75.75Z"/>
                <path d="m41.64,47.5h-10.22c-1.82,0-3.31-1.48-3.31-3.31v-6.21c0-1.01.45-1.95,1.24-2.59l5.02-4.02c1.22-.97,2.92-.97,4.13,0l5.2,4.16c.79.63,1.24,1.57,1.24,2.59v6.06c0,1.82-1.48,3.31-3.31,3.31Zm-5.2-15.34c-.4,0-.8.13-1.13.4l-5.02,4.02c-.43.34-.68.86-.68,1.41v6.21c0,1,.81,1.81,1.81,1.81h10.22c1,0,1.81-.81,1.81-1.81v-6.06c0-.55-.25-1.07-.68-1.41l-5.2-4.16c-.33-.27-.73-.4-1.13-.4Z"/>
                <path d="m10.97,22.26c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07-.48,1.07-1.07,1.07Zm0-1.5c-.24,0-.43.19-.43.43s.19.43.43.43.43-.19.43-.43-.19-.43-.43-.43Z"/>
                <path d="m10.97,32.49c-.59,0-1.07-.48-1.07-1.07s.48-1.07,1.07-1.07,1.07.48,1.07,1.07-.48,1.07-1.07,1.07Zm0-1.5c-.24,0-.43.19-.43.43s.19.43.43.43.43-.19.43-.43-.19-.43-.43-.43Z"/>
                <path d="m31.42,21.94h-12.78c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h12.78c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                <path d="m21.19,32.17h-2.56c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h2.56c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
              </g>
            </svg>`,
    dakisolatie: `<svg id="Group_27531" data-name="Group 27531" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.91 25.5">
                  <g id="Group_27530" data-name="Group 27530">
                    <path d="m25.46,25.5H1.46c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h24c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                    <path d="m3.57,25.5c-.41,0-.75-.34-.75-.75V8.58c0-.41.34-.75.75-.75s.75.34.75.75v16.17c0,.41-.34.75-.75.75Z"/>
                    <path d="m23.34,25.5c-.41,0-.75-.34-.75-.75V8.58c0-.41.34-.75.75-.75s.75.34.75.75v16.17c0,.41-.34.75-.75.75Z"/>
                    <path d="m26.16,11.84c-.18,0-.36-.06-.5-.19L14.83,2.02c-.79-.7-1.97-.7-2.76,0L1.25,11.65c-.31.28-.78.25-1.06-.06-.28-.31-.25-.78.06-1.06L11.08.9c1.35-1.2,3.39-1.2,4.75,0l10.83,9.63c.31.28.34.75.06,1.06-.15.17-.35.25-.56.25Z"/>
                    <path d="m9.4,15.94c-.41,0-.75-.34-.75-.75v-5.77c0-.41.34-.75.75-.75s.75.34.75.75v5.77c0,.41-.34.75-.75.75Z"/>
                    <path d="m11.69,12.49c-.19,0-.39-.07-.53-.22l-1.77-1.78-1.77,1.78c-.29.29-.77.3-1.06,0-.29-.29-.3-.77,0-1.06l2.3-2.31c.28-.28.78-.28,1.06,0l2.3,2.31c.29.29.29.77,0,1.06-.15.15-.34.22-.53.22Z"/>
                    <path d="m17.52,15.94c-.41,0-.75-.34-.75-.75v-5.77c0-.41.34-.75.75-.75s.75.34.75.75v5.77c0,.41-.34.75-.75.75Z"/>
                    <path d="m19.82,12.49c-.19,0-.39-.07-.53-.22l-1.77-1.78-1.77,1.78c-.29.29-.77.29-1.06,0-.29-.29-.3-.77,0-1.06l2.3-2.31c.28-.28.78-.28,1.06,0l2.3,2.31c.29.29.29.77,0,1.06-.15.15-.34.22-.53.22Z"/>
                  </g>
                </svg>`,
    glas: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 22.5">
          <g id="interior-windows-9">
            <path d="m21.75,22.5H.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h21c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
            <path d="m20.58,22.5c-.41,0-.75-.34-.75-.75V3.08c0-.87-.71-1.58-1.58-1.58H4.25c-.87,0-1.58.71-1.58,1.58v18.67c0,.41-.34.75-.75.75s-.75-.34-.75-.75V3.08C1.17,1.38,2.55,0,4.25,0h14c1.7,0,3.08,1.38,3.08,3.08v18.67c0,.41-.34.75-.75.75Z"/>
            <path d="m7.75,14.33c-.19,0-.38-.07-.53-.22-.29-.29-.29-.77,0-1.06l5.83-5.83c.29-.29.77-.29,1.06,0s.29.77,0,1.06l-5.83,5.83c-.15.15-.34.22-.53.22Z"/>
            <path d="m6.58,9.67c-.19,0-.38-.07-.53-.22-.29-.29-.29-.77,0-1.06l2.33-2.33c.29-.29.77-.29,1.06,0s.29.77,0,1.06l-2.33,2.33c-.15.15-.34.22-.53.22Z"/>
          </g>
        </svg>`,
    ketel: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.15 28.18">
          <g id="Group_27536" data-name="Group 27536">
            <path d="m17.63,25.96H2.51c-1.39,0-2.51-1.13-2.51-2.51V2.51C0,1.13,1.13,0,2.51,0h15.12c1.39,0,2.51,1.13,2.51,2.51v20.93c0,1.39-1.13,2.51-2.51,2.51ZM2.51,1.5c-.56,0-1.01.45-1.01,1.01v20.93c0,.56.45,1.01,1.01,1.01h15.12c.56,0,1.01-.45,1.01-1.01V2.51c0-.56-.45-1.01-1.01-1.01H2.51Z"/>
            <path d="m19.11,6.97H1.04c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h18.07c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
            <path d="m13.46,21.49h-6.77c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h6.77c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
            <path d="m6.69,28.18c-.41,0-.75-.34-.75-.75v-2.16c0-.41.34-.75.75-.75s.75.34.75.75v2.16c0,.41-.34.75-.75.75Z"/>
            <path d="m13.46,28.18c-.41,0-.75-.34-.75-.75v-2.16c0-.41.34-.75.75-.75s.75.34.75.75v2.16c0,.41-.34.75-.75.75Z"/>
            <path d="m10.08,17.13h0c-1.77,0-3.2-1.44-3.21-3.2,0-.78.27-1.55.78-2.15.05-.06.11-.11.17-.15l.95-1.12c.14-.17.35-.26.57-.26h0c.22,0,.43.1.57.26l.62.72.2-.23c.14-.17.35-.26.57-.26h0c.22,0,.43.1.57.26l.61.71c.51.6.79,1.36.79,2.14,0,1.84-1.44,3.28-3.2,3.28Zm-1.4-4.23c-.2.3-.31.66-.31,1.02,0,.94.76,1.7,1.7,1.7h0c.94,0,1.7-.76,1.7-1.7,0-.51-.15-.92-.43-1.24l-.04-.05-.2.23c-.14.17-.35.26-.57.26h0c-.22,0-.43-.1-.57-.26l-.62-.72-.53.62s-.09.09-.14.13Z"/>
          </g>
        </svg>`,
    overig: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 25.42">
              <g id="Group_27538" data-name="Group 27538">
                <path d="m16.34,18.93h-7.17c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h7.17c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                <path d="m13.29,25.42s-.09,0-.14-.01h-1.01c-1.93,0-3.5-1.54-3.57-3.45-.01-.05-.02-.1-.02-.14v-3.02s-.01-.07-.01-.11c0-.07-.02-.13-.05-.19-.02-.01-.05-.03-.07-.04-1.65-1.15-2.75-2.88-3.1-4.86-.35-1.98.09-3.98,1.24-5.63,1.15-1.65,2.88-2.75,4.86-3.1,1.98-.35,3.98.09,5.63,1.24s2.75,2.88,3.1,4.86c.35,1.98-.09,3.98-1.24,5.63-.52.73-1.13,1.33-1.84,1.83-.03.02-.07.04-.1.06-.06.07-.1.15-.1.25v3.11c0,1.97-1.61,3.57-3.58,3.57Zm-3.24-3.72s.01.09.01.13c0,1.14.93,2.08,2.08,2.08h1.13s.08,0,.12,0c1.09-.06,1.96-.96,1.97-2.07v-3.12c0-.64.33-1.23.87-1.57.03-.02.06-.03.09-.05.52-.38.97-.84,1.35-1.37.93-1.33,1.29-2.94,1-4.52s-1.16-2.97-2.48-3.89c-2.72-1.9-6.49-1.24-8.4,1.49-.92,1.32-1.28,2.92-.99,4.51.26,1.45,1.02,2.73,2.15,3.64.09.02.18.06.25.12.46.33.77.85.83,1.41.02.06.02.12.02.18v3.02Z"/>
                <path d="m20.39,5.86c-.19,0-.38-.07-.53-.22-.29-.29-.3-.77,0-1.06l.84-.85c.29-.3.77-.3,1.06,0,.29.29.3.77,0,1.06l-.84.85c-.15.15-.34.22-.53.22Z"/>
                <path d="m4.27,21.98c-.19,0-.38-.07-.53-.22-.3-.29-.3-.76,0-1.06l.84-.85c.29-.29.77-.3,1.06,0,.3.29.3.76,0,1.06l-.84.85c-.15.15-.34.22-.53.22Z"/>
                <path d="m24.75,13.5h-1.2c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h1.2c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                <path d="m1.95,13.5H.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h1.2c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                <path d="m21.23,21.98c-.19,0-.39-.07-.53-.22l-.84-.85c-.29-.29-.29-.77,0-1.06.29-.29.77-.29,1.06,0l.84.85c.29.29.29.77,0,1.06-.15.14-.34.22-.53.22Z"/>
                <path d="m5.11,5.86c-.19,0-.39-.07-.53-.22l-.84-.85c-.29-.3-.29-.77,0-1.06.3-.29.77-.29,1.06,0l.84.85c.29.3.29.77,0,1.06-.15.14-.34.22-.53.22Z"/>
                <path d="m12.75,2.7c-.41,0-.75-.34-.75-.75V.75c0-.41.34-.75.75-.75s.75.34.75.75v1.2c0,.41-.34.75-.75.75Z"/>
              </g>
            </svg>`,
    spouwmuur:`<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.5 23.5">
              <g id="slider-windows-horizontal-modern">
                <path d="m9.3,23.5c-.33,0-.66-.08-.96-.25l-7.02-3.95C.51,18.84,0,17.98,0,17.05V6.86c0-.34.09-.67.25-.97.53-.95,1.74-1.29,2.69-.75l7.02,3.95c.81.46,1.32,1.32,1.32,2.25v10.14s0,.02,0,.04c0,.34-.09.67-.25.97-.36.64-1.03,1-1.72,1ZM1.97,6.39c-.17,0-.33.09-.41.24-.04.07-.06.15-.06.23v10.18c0,.39.21.75.55.94l7.02,3.95c.23.13.52.05.64-.18.04-.06.06-.13.06-.21,0,0,0-.02,0-.02v-10.18c0-.39-.21-.75-.55-.95l-7.02-3.95c-.07-.04-.15-.06-.23-.06Z"/>
                <path d="m15.42,21.06c-.34,0-.68-.09-.97-.25l-4.29-2.41c-.36-.2-.49-.66-.29-1.02.2-.36.66-.49,1.02-.29l4.29,2.41c.18.1.42.07.57-.08.09-.09.14-.21.14-.33v-10.18c0-.39-.21-.75-.55-.95l-7.02-3.95c-.3-.17-.71.07-.7.41v3.79c0,.41-.34.75-.75.75s-.75-.34-.75-.75v-3.79c0-1.09.88-1.97,1.97-1.97.34,0,.68.09.97.25l7.02,3.95c.81.46,1.32,1.32,1.32,2.25v10.18c0,.53-.2,1.02-.58,1.39s-.87.58-1.39.58Z"/>
                <path d="m21.53,18.61c-.34,0-.67-.09-.97-.25l-4.29-2.41c-.36-.2-.49-.66-.29-1.02.2-.36.66-.49,1.02-.29l4.29,2.41c.3.17.7-.07.7-.41V6.46c0-.39-.21-.75-.55-.95l-7.02-3.95c-.3-.17-.71.07-.71.41v3.79c0,.41-.33.75-.75.75s-.75-.34-.75-.75V1.97c0-1.09.88-1.97,1.97-1.97.34,0,.67.09.97.25l7.02,3.95c.81.46,1.32,1.32,1.32,2.25v10.18c0,1.09-.88,1.97-1.97,1.97Z"/>
              </g>
            </svg>`,
    vloerisolatie:`<svg id="Group_27533" data-name="Group 27533" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.91 25.5">
                  <g id="Group_27532" data-name="Group 27532">
                    <path d="m25.46,25.5H1.46c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h24c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                    <path d="m3.57,25.5c-.41,0-.75-.34-.75-.75V8.58c0-.41.34-.75.75-.75s.75.34.75.75v16.17c0,.41-.34.75-.75.75Z"/>
                    <path d="m23.34,25.5c-.41,0-.75-.34-.75-.75V8.58c0-.41.34-.75.75-.75s.75.34.75.75v16.17c0,.41-.34.75-.75.75Z"/>
                    <path d="m26.16,11.84c-.18,0-.36-.06-.5-.19L14.83,2.02c-.79-.7-1.97-.7-2.76,0L1.25,11.65c-.31.28-.78.25-1.06-.06-.28-.31-.25-.78.06-1.06L11.08.9c1.35-1.2,3.39-1.2,4.75,0l10.83,9.63c.31.28.34.75.06,1.06-.15.17-.35.25-.56.25Z"/>
                    <path d="m17.51,22.28c-.41,0-.75-.34-.75-.75v-5.77c0-.41.34-.75.75-.75s.75.34.75.75v5.77c0,.41-.34.75-.75.75Z"/>
                    <path d="m17.52,22.28h0c-.2,0-.39-.08-.53-.22l-2.3-2.32c-.29-.29-.29-.77,0-1.06s.77-.29,1.06,0l1.77,1.78,1.77-1.78c.29-.29.77-.3,1.06,0,.29.29.3.77,0,1.06l-2.3,2.32c-.14.14-.33.22-.53.22Z"/>
                    <path d="m9.39,22.28c-.41,0-.75-.34-.75-.75v-5.77c0-.41.34-.75.75-.75s.75.34.75.75v5.77c0,.41-.34.75-.75.75Z"/>
                    <path d="m9.4,22.28h0c-.2,0-.39-.08-.53-.22l-2.3-2.32c-.29-.29-.29-.77,0-1.06.29-.29.77-.29,1.06,0l1.77,1.78,1.77-1.78c.29-.29.77-.3,1.06,0,.29.29.3.77,0,1.06l-2.3,2.32c-.14.14-.33.22-.53.22Z"/>
                  </g>
                </svg>`,
    vloerverwarming: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.28 27.07">
                      <g id="Group_27534" data-name="Group 27534">
                        <path d="m.75,27.07c-.41,0-.75-.34-.75-.75v-4.67c0-.41.34-.75.75-.75h18.7c.73,0,1.33-.59,1.33-1.33,0-.79-.59-1.38-1.33-1.38H2.83c-1.53-.03-2.75-1.25-2.78-2.76-.02-.75.26-1.47.78-2.02.52-.55,1.22-.85,1.98-.87h16.64c.73,0,1.33-.59,1.33-1.32s-.59-1.35-1.33-1.35H2.83c-1.56,0-2.83-1.27-2.83-2.83s1.27-2.84,2.82-2.84h17.95V.75c0-.41.34-.75.75-.75s.75.34.75.75v4.21c0,.41-.34.75-.75.75H2.83c-.73,0-1.33.6-1.33,1.33s.59,1.34,1.33,1.34h16.63c1.56,0,2.83,1.27,2.83,2.83s-1.27,2.85-2.83,2.85H2.83c-.34,0-.67.15-.91.41-.24.25-.38.59-.37.95.02.71.58,1.28,1.3,1.3h16.61c1.56,0,2.83,1.27,2.83,2.83s-1.27,2.88-2.83,2.88H1.5v3.92c0,.41-.34.75-.75.75Z"/>
                      </g>
                    </svg>`,
    warmtepomp:   `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.5 26.5">
                  <g id="fan-speed-square">
                    <path d="m13.25,17.12c-2.14,0-3.88-1.74-3.88-3.88s1.74-3.88,3.88-3.88,3.88,1.74,3.88,3.88-1.74,3.88-3.88,3.88Zm0-6.25c-1.31,0-2.38,1.07-2.38,2.38s1.07,2.38,2.38,2.38,2.38-1.07,2.38-2.38-1.07-2.38-2.38-2.38Z"/>
                    <path d="m20.19,26.5H6.31c-3.48,0-6.31-2.83-6.31-6.31V6.31C0,2.83,2.83,0,6.31,0h13.89c3.48,0,6.31,2.83,6.31,6.31v13.89c0,3.48-2.83,6.31-6.31,6.31ZM6.31,1.5C3.66,1.5,1.5,3.66,1.5,6.31v13.89c0,2.65,2.16,4.81,4.81,4.81h13.89c2.65,0,4.81-2.16,4.81-4.81V6.31c0-2.65-2.16-4.81-4.81-4.81H6.31Z"/>
                    <path d="m20.36,15.23c-.43,0-.85-.14-1.21-.4-.15-.11-.28-.24-.4-.39-.67-.85-1.62-1.4-2.69-1.55-.41-.06-.7-.44-.64-.85,0-.04.01-.08.03-.11-.02,0-.04,0-.06-.01-.4-.11-.63-.52-.52-.92.59-2.14,2.8-3.4,4.95-2.81,1.35.37,2.39,1.39,2.78,2.72.35,1.19.15,2.46-.57,3.48-.33.45-.81.74-1.35.82-.1.02-.21.02-.31.02Zm-4.05-3.82c1.44.21,2.73.96,3.63,2.11.03.04.07.07.1.1.12.09.26.12.4.1.14-.02.27-.1.36-.21.44-.63.57-1.43.35-2.18-.24-.83-.88-1.46-1.72-1.69-1.36-.37-2.75.42-3.12,1.76,0,0,0,.01,0,.02Z"/>
                    <path d="m10.97,11.63c-1.04-.29-1.89-.97-2.42-1.91-.53-.94-.65-2.02-.36-3.06.36-1.31,1.4-2.37,2.71-2.75,1.19-.35,2.46-.15,3.48.57.16.12.3.25.41.39.34.43.49.96.42,1.5-.07.54-.34,1.02-.77,1.36-.85.67-1.4,1.62-1.55,2.69-.06.41-.44.69-.85.64-.41-.06-.7-.44-.64-.85.2-1.46.95-2.76,2.11-3.67.11-.09.18-.22.2-.36.02-.14-.02-.29-.11-.4-.03-.04-.06-.07-.1-.1-.63-.44-1.42-.57-2.18-.35-.82.24-1.46.9-1.69,1.71-.18.65-.1,1.33.23,1.92.33.59.87,1.01,1.52,1.19l-.41,1.44Z"/>
                    <path d="m14.44,22.75c-.82,0-1.63-.25-2.32-.74-.17-.12-.3-.25-.41-.4-.33-.43-.48-.96-.42-1.5.07-.54.34-1.02.77-1.36.85-.67,1.4-1.62,1.55-2.69.06-.41.43-.7.85-.64.41.06.7.43.64.85-.2,1.46-.95,2.76-2.11,3.67-.11.09-.19.22-.2.36s.02.29.11.4c.03.04.07.07.11.1.62.44,1.42.57,2.18.35.83-.24,1.46-.88,1.69-1.72.18-.65.1-1.33-.23-1.92-.33-.59-.87-1.01-1.52-1.19l.39-1.45c1.05.3,1.91.97,2.44,1.91.53.94.66,2.02.36,3.06-.37,1.33-1.38,2.36-2.71,2.75-.38.11-.77.17-1.16.17Z"/>
                    <path d="m7.76,18.46c-.37,0-.74-.05-1.1-.15-1.33-.37-2.36-1.38-2.75-2.71-.35-1.19-.15-2.46.57-3.48.12-.16.25-.3.4-.41.89-.69,2.17-.54,2.87.35.67.85,1.62,1.4,2.69,1.55.41.06.7.43.64.85,0,.04-.02.08-.03.12.02,0,.04,0,.05.01.4.11.64.5.54.9-.3,1.08-.98,1.94-1.92,2.46-.61.34-1.28.51-1.96.51Zm-1.63-5.69c-.12,0-.23.04-.33.11-.04.03-.07.07-.1.11-.44.63-.57,1.43-.35,2.18.24.83.88,1.46,1.72,1.69.65.19,1.33.1,1.92-.23.59-.33,1.01-.87,1.2-1.52,0-.01,0-.02,0-.03-1.44-.21-2.73-.96-3.63-2.11-.11-.14-.27-.21-.43-.21Z"/>
                  </g>
                </svg>`,
    bouwbegeleiding: `<svg id="Group_27503" data-name="Group 27503" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.69 51.5">
                      <g id="Group_27505" data-name="Group 27505">
                        <path d="m36.8,51.5c-4.9,0-8.89-3.99-8.89-8.89s3.99-8.89,8.89-8.89,8.89,3.99,8.89,8.89-3.99,8.89-8.89,8.89Zm0-16.28c-4.07,0-7.39,3.32-7.39,7.39s3.31,7.39,7.39,7.39,7.39-3.31,7.39-7.39-3.32-7.39-7.39-7.39Z"/>
                        <path d="m31.37,17.78H11.99c-.82,0-1.59-.32-2.17-.9-.58-.58-.9-1.35-.9-2.18v-1.94C8.92,5.73,14.64,0,21.68,0s12.76,5.73,12.76,12.76v1.94c0,1.7-1.38,3.08-3.07,3.08ZM21.68,1.5c-6.21,0-11.26,5.05-11.26,11.26v1.94c0,.42.16.82.46,1.12.3.3.69.46,1.11.46v.75s0-.75,0-.75h19.37c.42,0,.82-.16,1.11-.46.3-.3.46-.69.46-1.11v-1.94c0-6.21-5.05-11.26-11.26-11.26Z"/>
                        <path d="m34.47,17.78H8.89c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h25.58c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                        <path d="m21.68,31.73c-2.68,0-5.21-1.04-7.1-2.94s-2.94-4.42-2.94-7.11v-4.65c0-.41.34-.75.75-.75s.75.34.75.75v4.65c0,2.29.89,4.43,2.51,6.05,1.61,1.61,3.76,2.5,6.04,2.5h0c4.71,0,8.55-3.84,8.55-8.55v-4.65c0-.41.34-.75.75-.75s.75.34.75.75v4.65c0,5.54-4.51,10.05-10.05,10.05h0Z"/>
                        <path d="m18.19,9.87c-.41,0-.75-.34-.75-.75V1.27c0-.41.34-.75.75-.75s.75.34.75.75v7.86c0,.41-.34.75-.75.75Z"/>
                        <path d="m25.17,9.87c-.41,0-.75-.34-.75-.75V1.27c0-.41.34-.75.75-.75s.75.34.75.75v7.86c0,.41-.34.75-.75.75Z"/>
                        <path d="m27.49,34.06c-.34,0-.64-.23-.73-.57l-.93-3.71c-.1-.4.14-.81.55-.91.4-.1.81.14.91.55l.93,3.71c.1.4-.14.81-.55.91-.06.02-.12.02-.18.02Z"/>
                        <path d="m.75,45.69C.34,45.69,0,45.36,0,44.94v-1.1c0-3.67,2.61-6.86,6.21-7.58l6.62-1.32c.61-.14,1.25-.07,1.82.17l1.42-5.69c.1-.4.51-.65.91-.55.4.1.65.51.55.91l-1.68,6.74c-.07.27-.27.48-.54.54-.26.07-.55-.01-.74-.21-.36-.39-.91-.55-1.43-.44l-6.64,1.33c-2.9.58-5.01,3.15-5,6.11v1.1c0,.41-.34.75-.75.75Z"/>
                        <path d="m39.04,45.6c-.19,0-.38-.07-.53-.22l-4.47-4.47c-.29-.29-.29-.77,0-1.06s.77-.29,1.06,0l4.47,4.47c.29.29.29.77,0,1.06-.15.15-.34.22-.53.22Z"/>
                        <path d="m34.57,45.6c-.19,0-.38-.07-.53-.22-.29-.29-.29-.77,0-1.06l4.47-4.47c.29-.29.77-.29,1.06,0s.29.77,0,1.06l-4.47,4.47c-.15.15-.34.22-.53.22Z"/>
                      </g>
                    </svg>`,
    mobile: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.91 27.51">
              <g id="Group_27417" data-name="Group 27417">
                <g id="Group_27389" data-name="Group 27389">
                  <path d="m14.16,27.51H2.75c-1.52,0-2.75-1.23-2.75-2.75V2.75C0,1.23,1.23,0,2.75,0h11.41c1.52,0,2.75,1.23,2.75,2.75v22.01c0,1.52-1.23,2.75-2.75,2.75ZM2.75,1.5c-.69,0-1.25.56-1.25,1.25v22.01c0,.69.56,1.25,1.25,1.25h11.41c.69,0,1.25-.56,1.25-1.25V2.75c0-.69-.56-1.25-1.25-1.25H2.75Z"/>
                  <path d="m9.73,4.12h-2.51c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h2.51c.41,0,.75.34.75.75s-.34.75-.75.75Z"/>
                </g>
              </g>
              <g>
                <circle cx="8.46" cy="23.62" r=".62"/>
                <path d="m8.46,24.51c-.5,0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9Zm0-1.24c-.19,0-.35.15-.35.35s.15.35.35.35.35-.15.35-.35-.15-.35-.35-.35Z"/>
              </g>
            </svg>`,
    check: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.94 16.5">
              <path d="m9.19,16.5c-.19,0-.38-.07-.53-.22L.22,7.84c-.29-.29-.29-.77,0-1.06s.77-.29,1.06,0l7.91,7.91L23.66.22c.29-.29.77-.29,1.06,0s.29.77,0,1.06l-15,15c-.15.15-.34.22-.53.22Z"/>
            </svg>`,
    angle: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.09 10.06">
              <polygon points="5.03 10.06 0 5.03 5.03 0 6.09 1.06 2.12 5.03 6.09 9 5.03 10.06"/>
            </svg>`,
    succes: `<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.11 58.11">
              <defs>
                <style>
                  .cls-1 {
                    fill: none;
                  }
                </style>
              </defs>
              <rect id="Rectangle_6272" data-name="Rectangle 6272" class="cls-1" width="58.11" height="58.11"/>
              <path d="m29.06,51.58h-14.53c-4.41,0-7.99-3.58-7.99-7.99V14.53c0-4.41,3.58-7.99,7.99-7.99h29.06c4.41,0,7.99,3.58,7.99,7.99v14.53c0,.4-.33.73-.73.73s-.73-.32-.73-.73v-14.53c0-3.61-2.93-6.54-6.54-6.54H14.53c-3.61,0-6.54,2.93-6.54,6.54v29.06c0,3.6,2.93,6.54,6.54,6.54h14.53c.4,0,.73.33.73.73s-.33.73-.73.73Z"/>
              <path d="m29.06,39.47h-12.11c-.4,0-.73-.33-.73-.73s.33-.73.73-.73h12.11c.4,0,.73.33.73.73s-.33.73-.73.73Z"/>
              <path d="m41.16,29.78h-7.26c-.4,0-.73-.32-.73-.73s.33-.73.73-.73h7.26c.4,0,.73.33.73.73s-.33.73-.73.73Z"/>
              <path d="m41.16,20.1h-7.26c-.4,0-.73-.33-.73-.73s.33-.73.73-.73h7.26c.4,0,.73.33.73.73s-.33.73-.73.73Z"/>
              <path d="m42.37,47.94c-.19,0-.37-.07-.51-.21l-3.63-3.63c-.28-.28-.28-.74,0-1.03s.74-.28,1.03,0l3.12,3.12,5.54-5.54c.28-.28.74-.28,1.03,0s.28.74,0,1.03l-6.05,6.05c-.14.14-.33.21-.51.21Z"/>
              <path d="m24.82,29.83h-6.05c-1.4,0-2.54-1.14-2.54-2.54v-3.8c0-.77.34-1.5.95-1.98l2.65-2.12c1.16-.93,2.78-.93,3.93,0l2.66,2.12c.61.49.95,1.21.95,1.99v3.8c0,1.4-1.14,2.54-2.54,2.54Zm-3.03-9.69c-.37,0-.75.12-1.06.37l-2.65,2.12c-.26.21-.41.52-.41.85v3.8c0,.6.49,1.09,1.09,1.09h6.05c.6,0,1.09-.49,1.09-1.09v-3.8c0-.33-.15-.64-.41-.85l-2.65-2.12c-.31-.25-.69-.37-1.06-.37Z"/>
            </svg>`,
};
const domain_url = "https://aanvraag.jobse.nl";

export const postBackup = (data) => {

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		keepalive: true,
		body: JSON.stringify(data)
	};

	return new Promise((resolve, reject) => {
		if (
			(window.location.href.includes("localhost:8080") && !window.location.href.includes("debug"))
			|| (!window.location.href.includes("localhost:8080") && window.location.href.includes("debug"))
		) {
			resolve({ success: true });
			return;
		}
		let url = `${domain_url}/helper/backup.php`;
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json(); // Parse the response JSON
			})
			.then((responseData) => {
				console.log('Response data:', responseData);
				// You can work with the response data here
				resolve(responseData);
			})
			.catch((error) => {
				console.error('Error:', error);
				reject(error);
			});
	});
};

export const postKadaster = (data) => {

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		keepalive: true,
		body: JSON.stringify(data)
	};

	return new Promise((resolve, reject) => {
		let url = `${domain_url}/helper/kadaster.php`;
		if (
			(window.location.href.includes("localhost:8080") && !window.location.href.includes("debug"))
			|| (!window.location.href.includes("localhost:8080") && window.location.href.includes("debug"))
		) {
			url += "?debug";
		}
		fetch(url, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json(); // Parse the response JSON
			})
			.then((responseData) => {
				console.log('Response data:', responseData);
				// You can work with the response data here
				resolve(responseData);
			})
			.catch((error) => {
				console.error('Error:', error);
				reject(error);
			});
	});
};

export const postBrevo = (data, action = "new") => {

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		keepalive: true,
		body: JSON.stringify(data)
	};

	return new Promise((resolve, reject) => {
		if (
			(window.location.href.includes("localhost:8080") && !window.location.href.includes("debug"))
			|| (!window.location.href.includes("localhost:8080") && window.location.href.includes("debug"))
		) {
			resolve({ success: true });
			return;
		}
		fetch(`${domain_url}/helper/brevo-${action}.php`, requestOptions)
			.then((response) => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json(); // Parse the response JSON
			})
			.then((responseData) => {
				console.log('Response data:', responseData);
				// if (responseData.error && responseData.result.code === "duplicate_parameter") {
				// 	return postBrevo(data, "update");
				// } else {
				// You can work with the response data here
				resolve(responseData);
			})
			.catch((error) => {
				console.error('Error:', error);
				reject(error);
			});
	});
};